<template>
  <div class="brandlider-cls mb-5">
    <div class="brand-slider-main brand-slider round-arrow" v-if="brandList && brandList.length > 0">
      <div id="testimonial-brand" class="owlcarousel">
        <VueSlickCarousel v-bind="settings" class="pill-nav">
          <div v-for="(brand, index) in brandList" :key="index" class="vue-slide-carousel">
            <router-link :to="{ name: 'brand-items', params: { brand_id: base64_encode(brand.id) } }"
              v-if="brand.restaurants.length > 1" class="brand-link 1">
              <div class="testimonial-brnd" @click="settitle(brand.name)"><img :src="brand.image" />
                <p>{{ brand.name }}</p>
              </div>
            </router-link>
            <router-link :to="{ name: 'vendor', params: { restaurant_name: brand.restaurants_slug[0] } }"
              v-if="brand.restaurants.length == '1'" class="brand-link 2">
              <div class="testimonial-brnd">
                <img :src="brand.image" />
                <p>{{ brand.name }}</p>
              </div>
            </router-link>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  props: ['latitude', 'longitude', 'is_pickup_delivery'],
  components: { VueSlickCarousel },
  data() {
    return {
      loading: true,
      brandList: [],
      brandZone: '',
      settings: {
        "focusOnSelect": true,
        "touchThreshold": 5,
        "dots": false,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 8,
        "slidesToScroll": 8,
        // "initialSlide": 0,
        // "centerMode": false,
        // "centerPadding":'40px',
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 6,
              "slidesToScroll": 6,
              "infinite": true,
              "dots": false
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 1,
              "dots": true,
              "arrows": false
            }
          }
        ]
      },
    };
  },

  mounted() {
    this.getBrands(this.latitude, this.longitude);
    // this.getBanners()
  },

  watch: {
    latitude: function () { setTimeout(() => { this.getBrands() }, 1000); },
    is_pickup_delivery: function () { setTimeout(() => { this.getBrands(); }, 1000); },
  },

  computed: {
    ...mapGetters("restaurant", ["brandListState"]),
    ...mapGetters("restaurant", ["bannerListState"]),


  },

  methods: {
    ...mapActions("restaurant", ["getBrandListData"]),
    ...mapActions("banner", ["getBanners"]),
    ...mapActions("setting", ["getDeliveryZoneSetting"]),


    getBrands(latitude, longitude) {
      if (this.brandListState && this.brandListState.length > 0) {
        this.brandList = this.brandListState;
        this.$emit("getBrandRestaurantData", true);
      } else {
        latitude = this.latitude;
        longitude = this.longitude;
        let isGetRestaurant = true;
        this.loading = true;
        if (latitude) {
          if (this.$auth.setting && this.$auth.setting.is_single_restaurant == 'No') {
            this.brandList = [];
            this.getBrandListData({
              vendor_id: this.$auth.getVendorId(),
              latitude: latitude,
              longitude: longitude,
              delivery_type_time_slots: this.is_pickup_delivery,
              is_langauge: this.$store.state.lang.locale
            }).then((data) => {
              if (data.code == 200) {
                this.getDeliveryZoneSetting({
                  vendor_id: this.$auth.getVendorId(),
                  is_language: this.$store.state.lang.locale,
                  lat: latitude,
                  lan: longitude,
                }).then((zoneData) => {
                  if (zoneData.Result && zoneData.Result.is_delivery_zone_available == "Yes") {
                    this.brandList = data.Result;
                  } else {
                    this.brandList = [];
                  }
                  isGetRestaurant = true;
                  this.$emit("getBrandRestaurantData", isGetRestaurant);
                }).catch((error) => {
                  console.error("Error fetching delivery zone settings:", error);
                  // Handle error
                });
              } else if (data.code == 101) {
                isGetRestaurant = false;
                this.brandList = [];
                this.loading = false;
                this.$emit("getBrandRestaurantData", isGetRestaurant);
              } else {
                this.brandList = [];
                this.loading = false;
              }
            }).catch((error) => {
              console.error("Error fetching brand list data:", error);
              // Handle error
            });
          }
        }
      }

    },
    settitle(value) {
      localStorage.setItem("brandname", value);
    }
  }
}
</script>